
import { defineComponent, reactive, ref, nextTick, toRefs } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import { message } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'
import BrandSelector from '@/views/RetailValidation/component/BrandSelector.vue'
import ProgramCodeMapping from '@/views/Claim/SsscOrder/ProgramCodeMapping.vue'
import StartSystemClaim from '@/views/Claim/SsscOrder/StartSystemClaim.vue'
import { downloadFromStream } from '@/utils/payment/downloadFile'
import { elementSize } from '@/utils'
import {
  getOtrStatus as getOtrStatusAPI,
  getOtrList as getOtrListAPI,
  otrExport as otrExportAPI
} from '@/API/claim/claimSsscOrder'

enum BatchQueryType {
  FIN,
  VIN
}

export default defineComponent({
  components: {
    BrandSelector,
    ProgramCodeMapping,
    StartSystemClaim
  },
  setup() {
    const { t, locale } = useI18n()
    const { getters: {isDealer}, state } = useStore()

    const tableSize = elementSize('.data-table')

    const conditions = reactive<any>({
      brand: undefined,
      updatedTime: [undefined, undefined],
      finNo: '',
      finNoList: [],
      vinNo: '',
      vinNoList: [],
      programCode: '',
      packageValidFrom: [undefined, undefined],
      typeClass: '',
      status: undefined,
      description: ''
    })

    const statusList = ref<string[]>([])

    const programCodeMappingVisible = ref(false)
    const startSystemClaimVisible = ref(false)

    let batchQueryType: BatchQueryType = BatchQueryType.FIN

    // 批量查询
    const batchQuery = reactive({
      batchQueryTitle: "",
      batchQueryModal: false,
      batchQueryListTitle: "",
    });
    //Modal的输入框
    const textContentNo = ref<string>("");
    //输入框，用于控制焦点
    const input = ref();
    //显示的VIN数组
    const textNoArray = ref<string[]>([]);

    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      switch (batchQueryType) {
        case BatchQueryType.FIN:
          conditions.finNo = conditions.finNoList?.[0] ?? ''
          break
        case BatchQueryType.VIN:
          conditions.vinNo = conditions.vinNoList?.[0] ?? ''
          break
      }
    };
    // 批量查询按钮事件
    const batchQueryEvent = (type: BatchQueryType) => {
      batchQueryType = type
      batchQuery.batchQueryModal = true
      switch (type) {
        case BatchQueryType.FIN:
          batchQuery.batchQueryTitle = locale.value === 'en' ? 'FIN Query' : '车架号(FIN)查询'
          batchQuery.batchQueryListTitle = locale.value === 'en' ? 'FIN list' : '车架号(FIN)列表'
          break;
        case BatchQueryType.VIN:
          batchQuery.batchQueryTitle = locale.value === 'en' ? 'VIN Query' : '车架号查询'
          batchQuery.batchQueryListTitle = locale.value === 'en' ? 'VIN list' : '车架号(FIN)列表'
          break;
      }

      textNoArray.value = conditions.vinNoList
      const dom = document.getElementsByClassName("list-content")[0]
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36)
        input.value.focus();
      });
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error("请从Excel中粘贴复制查询");
        textContentNo.value = "";
        return;
      }
      textNoArray.value = textNoArray.value.concat(
        textContentNo.value.split(/\s/).filter(item => item !== '')
      );
      const dom = document.querySelector(".list-content") as HTMLElement;
      textContentNo.value = "";
      switch (batchQueryType) {
        case BatchQueryType.FIN:
          conditions.finNoList = textNoArray.value
          break
        case BatchQueryType.VIN:
          conditions.vinNoList = textNoArray.value
          break
      }
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };

    // 清除批量查询
    const clearBatchQuery = () => {
      textNoArray.value = []
      switch (batchQueryType) {
        case BatchQueryType.FIN:
          conditions.finNo = ''
          conditions.finNoList = []
          break
        case BatchQueryType.VIN:
          conditions.vinNo = ''
          conditions.vinNoList = []
          break
      }
      input.value.focus();
    };

    const columns = [
      {
        title: t('ssscOrder.result'),
        dataIndex: 'result',
        width: 80
      },
      {
        title: t('ssscOrder.errorMessage'),
        dataIndex: 'errorMessage',
        width: 200
      },
      {
        title: t('ssscOrder.claimID'),
        dataIndex: 'claimId',
        width: 80
      },
      {
        title: t('ssscOrder.startSystemClaimDate'),
        dataIndex: 'startSystemClaimDate',
        width: 150
      },
      {
        title: t('ssscOrder.vin'),
        dataIndex: 'vin',
        width: 160
      },
      {
        title: t('ssscOrder.fin'),
        dataIndex: 'fin',
        width: 160
      },
      {
        title: t('ssscOrder.brand'),
        dataIndex: 'brand',
        width: 80
      },
      {
        title: t('ssscOrder.typeClass'),
        dataIndex: 'typeClass',
        width: 80
      },
      {
        title: t('ssscOrder.updatedTime'),
        dataIndex: 'updatedTime',
        width: 150
      },
      {
        title: t('ssscOrder.createdTime'),
        dataIndex: 'createdTime',
        width: 150
      },
      {
        title: t('ssscOrder.activateDate'),
        dataIndex: 'activateDate',
        width: 150
      },
      {
        title: t('ssscOrder.description'),
        dataIndex: 'description',
        width: 250
      },
      {
        title: t('ssscOrder.status'),
        dataIndex: 'status',
        width: 150
      },
      {
        title: t('ssscOrder.programCode'),
        dataIndex: 'programCode',
        width: 130
      },
      {
        title: t('ssscOrder.packageName'),
        dataIndex: 'packageName',
        width: 150
      },
      {
        title: t('ssscOrder.dealerCode'),
        dataIndex: 'dealerCode',
        width: 100
      },
      {
        title: t('ssscOrder.outletId'),
        dataIndex: 'outletId',
        width: 110
      },
      {
        title: t('ssscOrder.dealerName'),
        dataIndex: 'dealerName',
        width: 300
      },
      {
        title: t('ssscOrder.productCode'),
        dataIndex: 'productCode',
        width: 150
      },
      {
        title: t('ssscOrder.wholesalePrice'),
        dataIndex: 'wholesalePrice',
        width: 110,
        align: 'right'
      },
      {
        title: t('ssscOrder.iconContractNumber'),
        dataIndex: 'iconContractNumber',
        width: 150
      },
    ];

    const data = ref<any[]>([])

    const pagination = reactive({
      current: 1,
      pageSize: 20,
      total: 0
    })

    getOtrStatusAPI().then(data => {
      statusList.value = data
    })

    const handleReset = () => {
      conditions.brand = undefined
      conditions.updatedTime = [undefined, undefined]
      conditions.finNo = ''
      conditions.finNoList = []
      conditions.vinNo = ''
      conditions.vinNoList = []
      conditions.programCode = '',
      conditions.packageValidFrom = [undefined, undefined]
      conditions.typeClass = ''
      conditions.status = undefined,
      conditions.description = ''
    }

    const handleQueryData = async () => {
      const res = await getOtrListAPI({
        brand: conditions.brand,
        bus: isDealer ? [] : state.user.buList.map((item: any) => item.nameEn),
        dealerCode: isDealer ? state.user.organization.entityCode : '',
        description: conditions.description,
        fin: conditions.finNoList.length ? '' : conditions.finNo,
        finList: conditions.finNoList,
        packageValidFromFrom: conditions.packageValidFrom?.[0] ?
          moment(conditions.packageValidFrom?.[0]).format('YYYY-MM-DD')  : '',
        packageValidFromTo: conditions.packageValidFrom?.[1] ?
          moment(conditions.packageValidFrom?.[1]).format('YYYY-MM-DD')  : '',
        programCode: conditions.programCode,
        status: conditions.status,
        type: isDealer ? '1' : '2',
        typeClass: conditions.typeClass,
        updatedTimeFrom: conditions.updatedTime?.[0] ?
          moment(conditions.updatedTime?.[0]).format('YYYY-MM-DD')  : '',
        updatedTimeTo: conditions.updatedTime?.[1] ?
          moment(conditions.updatedTime?.[1]).format('YYYY-MM-DD')  : '',
        vin: conditions.vinNoList.length ? '' : conditions.vinNo,
        vinList: conditions.vinNoList,
        page: pagination.current,
        pageSize: pagination.pageSize
      })
      data.value = res.content
      pagination.total = res.totalElements
    }

    const handleSearch = () => {
      pagination.current = 1
      handleQueryData()
    }

    const handleExport = async () => {
      const res = await otrExportAPI({
        brand: conditions.brand,
        bus: isDealer ? [] : state.user.buList.map((item: any) => item.nameEn),
        dealerCode: isDealer ? state.user.organization.entityCode : '',
        description: conditions.description,
        fin: conditions.finNoList.length ? '' : conditions.finNo,
        finList: conditions.finNoList,
        packageValidFromFrom: conditions.packageValidFrom?.[0] ?
          moment(conditions.packageValidFrom?.[0]).format('YYYY-MM-DD')  : '',
        packageValidFromTo: conditions.packageValidFrom?.[1] ?
          moment(conditions.packageValidFrom?.[1]).format('YYYY-MM-DD')  : '',
        programCode: conditions.programCode,
        status: conditions.status,
        type: isDealer ? '1' : '2',
        typeClass: conditions.typeClass,
        updatedTimeFrom: conditions.updatedTime?.[0] ?
          moment(conditions.updatedTime?.[0]).format('YYYY-MM-DD')  : '',
        updatedTimeTo: conditions.updatedTime?.[0] ?
          moment(conditions.updatedTime?.[0]).format('YYYY-MM-DD')  : '',
        vin: conditions.vinNoList.length ? '' : conditions.vinNo,
        vinList: conditions.vinNoList
      })
      downloadFromStream(res)
    }

    const handleShowProgramCodeMappingDrawer = () => {
      programCodeMappingVisible.value = true
    }

    const handleShowStartSystemClaimDrawer = () => {
      startSystemClaimVisible.value = true
    }

    handleSearch()

    return {
      locale,
      statusList,
      conditions,
      BatchQueryType,
      input,
      textNoArray,
      textContentNo,
      ...toRefs(batchQuery),
      batchQueryEvent,
      textChangEvent,
      clearBatchQuery,
      closeBatchQueryModal,
      tableSize,
      columns,
      data,
      pagination,
      handleReset,
      handleQueryData,
      handleSearch,
      handleExport,
      programCodeMappingVisible,
      startSystemClaimVisible,
      handleShowProgramCodeMappingDrawer,
      handleShowStartSystemClaimDrawer
    }
  },
});
